import React from 'react'
import ReactDOM from 'react-dom/client'
import defaultFeatures from './features'
import cookie from 'cookie'
import { mergeFeatures } from './context/feature'
import App from './App'
import 'normalize.css'
import './index.css'
import reportWebVitals from './reportWebVitals'

const cookies = cookie.parse(document.cookie)
const cookieFeatures = (cookies.features || '').split(/\s*,\s*/g)

const features = mergeFeatures(defaultFeatures, cookieFeatures)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App features={features} />
  </React.StrictMode>,
)

reportWebVitals()
