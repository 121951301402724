import React, { ReactNode } from 'react'
import styles from './System.module.css'

function System({ children }: { children: ReactNode }) {
  return (
    <div className={styles.layout}>
      <div className={styles.content}>
        {children}
        <p>v0.1.0</p>
      </div>
    </div>
  )
}

export default System
