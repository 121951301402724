import React from 'react'
import { FeaturesProvider } from './context/feature'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Confirm, Home, Join, NotFound, OAuth, Success } from './views'
import './App.css'
import { AuthProvider } from './context/oauth'

function App({ features }: { features: string[] }) {
  return (
    <FeaturesProvider features={features}>
      <AuthProvider
        authorizeUrl={process.env.REACT_APP_AUTH_URL + '/authorize'}
        tokenUrl={process.env.REACT_APP_AUTH_URL + '/token'}
        clientId="frontend"
        scope="common"
        redirectPath="/oauth"
      >
        <BrowserRouter>
          <div className="app">
            <Routes>
              <Route path="/" element={<Home />} />
              {features.includes('OAUTH') ? <Route path="/oauth" element={<OAuth />} /> : null}
              <Route path="/join" element={<Join />} />
              <Route path="/join/confirm" element={<Confirm />} />
              <Route path="/join/success" element={<Success />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </FeaturesProvider>
  )
}

export default App
