import React from 'react'
import { System } from '../../components/layout'
import { Link } from 'react-router-dom'
import { useAuth } from '../../context/oauth'
import { AlertError } from '../../components/violation'

function OAuth() {
  const { error, loading } = useAuth()
  return (
    <System>
      <h1>OAuth</h1>
      <AlertError message={error} />
      {loading ? <p>Loading...</p> : null}
      <p>
        <Link to="/">Back to Home</Link>
      </p>
    </System>
  )
}

export default OAuth
