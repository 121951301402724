import React from 'react'
import { Link } from 'react-router-dom'
import { System } from '../../components/layout'
import { JoinForm } from '../../components/forms'

function Join() {
  return (
    <System>
      <h1>Join to Us</h1>
      <JoinForm />
      <p>
        <Link to="/">Back to Home</Link>
      </p>
    </System>
  )
}

export default Join
